import React, { useEffect, useState } from 'react'
 
import "./bidsuccessfully.scss"
import { Link, useParams } from 'react-router-dom'

 
import { Helmet } from 'react-helmet'
import Header from '../../Layout/Header/Index'
import Footer from '../../Layout/Footer/Index'
import { axiosApi } from '../../_helper/api_helper'

const BidSuccessfullydone = () => {
    
    const client_Id = localStorage.getItem("client_Id")
    const [successData, setSuccessData] = useState({})


    useEffect(() => {
        if (client_Id) {
            axiosApi.get(`/clientdata/${client_Id}`).then((response) => {
                setSuccessData(response?.data?.client)
            })
        }

    }, [client_Id]);
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>Interst Shown</title>
            </Helmet>
            <Header />
            <div className="interested">
                <div className="interested-container">
                    <div className="interested-content">
                        <div className='interested-image'><img src="/image/Vector.png" alt="" /></div>
                        {/* <div>😊 Great {successData?.recuiter_name}, for your showing interest .</div> */}
                        <div>😊 Great <b>{successData?.name}</b> , for bid on this project .</div>
                        {/* <div>Our team will contact you shortly</div> */}
                        <div><Link to='/all-project-posted'><button className='btn btn-dark'>Search More Project</button></Link> </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default BidSuccessfullydone
