import React from 'react'
import "./breadcrump.scss"
import { Link } from 'react-router-dom'

const BreadCrump = (props) => {
    return (
        <div className="breacrump-conatiner">
            <div className="breacrump-inner">
                <div className='breacrump-inner-heaidng'><h3>{props?.title}</h3></div>
               
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><Link to='/'>Home</Link>  </li>
                        <li class="breadcrumb-item active" aria-current="page">{props?.title}</li>
                    </ol>
                
            </div>
        </div>

    )
}

export default BreadCrump