import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import "./postprojectexcel.scss";
import Header from '../../Layout/Header/Index';
import Footer from '../../Layout/Footer/Index';
import { axiosApi } from '../../_helper/api_helper';

const PostProjectExcel = () => {
    const [excelData, setExcelData] = useState([]);
    const [fileName, setFileName] = useState("");
     

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setFileName(file.name); // Show file name after selection

        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const parsedData = XLSX.utils.sheet_to_json(sheet);
            setExcelData(parsedData);
        };

        if (file.name.endsWith(".csv")) {
            reader.readAsText(file); 
            reader.onload = (e) => {
                const csvData = XLSX.read(e.target.result, { type: "string" });
                const sheet = csvData.Sheets[csvData.SheetNames[0]];
                const parsedData = XLSX.utils.sheet_to_json(sheet);
                setExcelData(parsedData);
            };
        } else {
            reader.readAsArrayBuffer(file);
        }
    };

    const handleSubmit = async () => {
        if (excelData.length === 0) {
            alert("Please upload a file first.");
            return;
        }
    
        const recruiters = {};
    
        for (const row of excelData) {
            const { recuiter_name, offical_email, mobile, industry, City, input_prompt, project_budget } = row;
    
            if (!input_prompt || input_prompt.trim() === "") {
                console.error(`Missing input_prompt for ${offical_email}`);
                alert(`Error: Missing input_prompt for ${offical_email}`);
                return;
            }
    
            try {
                // Generate project title
                const titleResponse = await axiosApi.post("/generate-project-title", { input_prompt: input_prompt });
                let project_title = titleResponse.data?.title || "Default Title";
                project_title = project_title.replace(/^"|"$/g, ''); // Remove extra quotes
    
                // Generate project description
                const descResponse = await axiosApi.post("/generate-project-discription", { input_prompt: input_prompt });
                const project_description = descResponse.data?.description || "Default Description";
    
                if (!recruiters[offical_email]) {
                    recruiters[offical_email] = {
                        recuiter_name,
                        offical_email,
                        mobile,
                        multijobs: []
                    };
                }
    
                recruiters[offical_email].multijobs.push({
                    project_title,
                    project_description,
                    project_budget: project_budget.replace(/[^\d–-]/g, ""),
                    industry,
                    city: City
                });
    
            } catch (error) {
                console.error("Error generating title/description:", error);
                alert(`Error generating title/description for ${offical_email}: ${error.response?.data?.message || error.message}`);
                return;
            }
        }
    
        for (const recruiter of Object.values(recruiters)) {
            try {
                const response = await axiosApi.post("/client-multijob/create", recruiter, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
    
                if (response.status === 200 || response.status === 201) {
                    console.log(`Data uploaded for ${recruiter.recuiter_name}`);
                } else {
                    console.error(`Unexpected response for ${recruiter.recuiter_name}`);
                }
            } catch (error) {
                console.error("Error uploading data:", error);
                alert(`Error uploading data for ${recruiter.recuiter_name}: ${error.response?.data?.message || error.message}`);
            }
        }
    
        alert("All data successfully uploaded!");
    };
    
    
    
    
    return (
        <>
            <Header />
            <div className="upload-container">
                <label htmlFor="fileUpload" className="upload-box">
                    <span>{fileName ? fileName : "Click to Upload or Drag & Drop"}</span>
                    <input 
                        type="file" 
                        accept=".xlsx, .xls, .csv" 
                        id="fileUpload" 
                        onChange={handleFileUpload} 
                    />
                </label>
                <button className="upload-btn" onClick={handleSubmit}>Submit Data</button>
            </div>
            <Footer />
        </>
    );
};

export default PostProjectExcel;
