
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosApi } from '../../_helper/api_helper';
import WarningIcon from '@mui/icons-material/Warning';
import "./receruitermodel.scss";

const RecuiterModal = (props) => {
    const { filterData, mutliJobId } = props;
    const isMobile = window.innerWidth <= 768;
    const navigate = useNavigate();
    const recuiter_id = localStorage.getItem("recuiter_Id");
    const client_id = localStorage.getItem("client_Id");
    const [recuiterById, setRecuiterById] = useState([]);


    const [interest, setInterst] = useState([]);
    const [showNoCreditsModal, setShowNoCreditsModal] = useState(false);
    const [showBidModal, setShowBidModal] = useState(false);
    const [bidAmount, setBidAmount] = useState("");
    const [errorMsg, setErrorMsg] = useState({});



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosApi.get(`/multijob/${mutliJobId}/${filterData}`);
                setRecuiterById(response?.data);
            } catch (error) {
                console.error("Error fetching recruiter details:", error);
            }
        };
        // fetchData()
        if (filterData) {
            fetchData();
        }
    }, [filterData]);


    useEffect(() => {
        if (client_id) {
            apiInterest()

        }
    }, [client_id])

    const apiInterest = async () => {
        const response = await axiosApi.get(`/clientdata/${client_id}`);
        setInterst(response?.data?.client
        )

    }

    const handleInterest = (checking) => {
        if (!client_id) {
            navigate('/register-as-freelancer');  
            return;  
        }
       
        const payload = {
            recruiter_id: mutliJobId,
            freelancer_id: client_id,
            client_id: client_id,
            multi_job_id: filterData
        }
        if (interest.status == 0) {
            return toast.warning('Your Profile is not active.', {
                position: 'top-left',
            });
        } else {
            setShowBidModal(true)
            // const userConfirmed = window.confirm("Are you sure you want to proceed?");
            // if (userConfirmed) {

            //     axiosApi.post("/freelancer-booking-recuiterjob", payload).then((response) => {
                 
            //         navigate(`/Success/${filterData}`);
            //     })

            //         .catch((error) => {
            //             console.error("Error while booking recruiter:", error);
            //             // setErrorMsg(error?.response?.data?.error)
            //             if (error.response?.status == 400) {
            //                 toast.warning(error?.response?.data?.error, {
            //                     position: 'top-left',
            //                 });
            //             } else {
            //                 setShowNoCreditsModal(true);
            //                 props?.onHide()
            //             }



            //         });

            // } else {

            // }
        }


    }
    const handleBidSubmit = () => {
        if (!bidAmount) {
          toast.error("Please enter a bid amount.", { position: "top-left" });
          return;
        }
    
        const payload = {
          recruiter_id: mutliJobId,
          freelancer_id: client_id,
          client_id: client_id,
          multi_job_id: filterData,
          bid_amount: bidAmount,
        };
    
        axiosApi.post("/freelancer-booking-recuiterjob", payload)
          .then((response) => {
            toast.success("Bid submitted successfully!", { position: "top-left" });
            setShowBidModal(false);
            // navigate(`/Success/${filterData}`);
          })
          .catch((error) => {
            console.error("Error while submitting bid:", error);
            if (error.response?.status === 400) {
              toast.warning(error?.response?.data?.error, { position: "top-left" });
            } else {
              setShowNoCreditsModal(true);
            }
          });
      };
    const calculateTimeElapsed = (createdAt) => {
        const currentDate = new Date();
        const postedDate = new Date(createdAt);
        const timeDifference = currentDate - postedDate;

        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(days / 30);
        const years = Math.floor(days / 365);

        if (years > 0) {
            return `${years} ${years === 1 ? 'year' : 'years'} ago`;
        } else if (months > 0) {
            return `${months} ${months === 1 ? 'month' : 'months'} ago`;
        } else if (weeks > 0) {
            return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`;
        } else if (days > 0) {
            return `${days} ${days === 1 ? 'day' : 'days'} ago`;
        } else if (hours > 0) {
            return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
        } else if (minutes > 0) {
            return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
        } else {
            return `${seconds} ${seconds === 1 ? 'second' : 'seconds'} ago`;
        }
    };
    return (
        <>
            <Modal
                {...props}

                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='recuiter-model-project'
            >
                <Modal.Header className='recuiter-model-project-header' closeButton>
                    <Modal.Title className='recuiter-model-project-title' id="contained-modal-title-vcenter">
                        <div style={{ fontSize: "17px", fontWeight: "400", color: "black" }}>Posted <span>{calculateTimeElapsed(recuiterById?.created_at)}</span></div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                            <button 
                            onClick={() => {
                                props.onHide(); // Close the "View Details" modal
                                handleInterest(interest?.status); // Show "Bid Now" modal
                              }}
                            // onClick={() => setShowBidModal(true)}
                             className='btn btn-dark'>I'm Interested</button>
                        </div>

                        {/* {props?.filterData?.results.project_budget} */}
                    </Modal.Title>
                    <div>
                        <h6 style={{ fontSize: "15px", fontWeight: "500", color: "green" }}>Required credit to submit interest  <span style={{ color: "black" }}> <img src="/image/coin.png" width='20px' height={'20px'} alt="" /> {recuiterById?.calculated_points}</span></h6>

                    </div>
                </Modal.Header>

                <Modal.Body >
                    <h5> <b>{recuiterById?.project_title?.replace(/^"|"$/g, '')}</b> </h5>

                    <div className='mt-1 mt-md-3'>
                        <span><b>Project Budget</b>
                        </span> :
                        <span className='ms-1' style={{ color: "green", fontWeight: "500", border: "1px solid grey", padding: "8px", borderRadius: "5px" }}>
                            ₹ {recuiterById.min_perhour && recuiterById.max_perhour
                                ? `${recuiterById.min_perhour} - ${recuiterById.max_perhour}`
                                : recuiterById.min_fixedprice && recuiterById.max_fixedprice
                                    ? `${recuiterById.min_fixedprice} - ${recuiterById.max_fixedprice}`
                                    : 'N/A'}
                        </span>
                        {recuiterById.min_perhour_usd || recuiterById.min_fixedprice_usd && (
                            <span className='ms-3' style={{ color: "green", fontWeight: "500", border: "1px solid grey", padding: "8px", borderRadius: "5px" }}>
                                US$ {recuiterById.min_perhour_usd && recuiterById.max_perhour_usd
                                    ? `${recuiterById.min_perhour_usd} - ${recuiterById.max_perhour_usd}`
                                    : recuiterById.min_fixedprice_usd && recuiterById.max_fixedprice_usd
                                        ? `${recuiterById.min_fixedprice_usd} - ${recuiterById.max_fixedprice_usd}`
                                        : 'null'}
                            </span>
                        )}

                    </div>

                    <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }} className='mt-1 mt-md-3'><span><b>Skill Required</b> </span> :

                        {recuiterById?.skills ? (
                            <span style={{ color: "black", fontWeight: "500", display: "flex", flexWrap: "wrap", gap: "5px" }}>
                                {recuiterById.skills.map((skill, index) => (
                                    <button key={index} className='btn btn-light'>{skill?.name}</button>
                                ))}
                            </span>
                        ) : (
                            <span>No skills specified</span>
                        )}
                    </div>
                    <p className='mt-1 mt-md-3'>
                        <pre style={{ whiteSpace: "pre-wrap", fontFamily: `"Poppins", sans-serif` }}
                            dangerouslySetInnerHTML={{
                                __html: recuiterById?.project_description?.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>"),
                            }}
                        >
                            {/* {recuiterById?.project_description} */}
                        </pre>

                    </p>
                    <h5 className='mt-1 mt-md-3'>Industry Required</h5>

                    {/* <div>Skills required:</div> */}
                    <div className='mt-1'>
                        <button style={{ background: "#F9E8C9", padding: "0.5rem", textTransform: "capitalize" }} className='btn btn-light'>{recuiterById?.industry}</button>

                    </div>
                </Modal.Body>

                {/* <Modal.Footer>
                    <Button style={{ background: "black" }} onClick={props.onHide}>Close</Button>
                </Modal.Footer> */}
            </Modal>




            <Modal
                show={showNoCreditsModal}
                onHide={() => setShowNoCreditsModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title><WarningIcon style={{ color: "#FF8F00" }} /> Credit Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* {errorMsg} */}
                    You don't have credit available in your account. <Link to='/credits'>Click here</Link> to buy credits.
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="primary" onClick={() => navigate('/credits')}>
                        Buy Points
                    </Button> */}
                    <Button variant="dark" onClick={() => setShowNoCreditsModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
{/* Bid Modal */}
<Modal show={showBidModal} onHide={() => setShowBidModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Submit Your Bid</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Enter Bid Amount:</label>
          <input
            type="number"
            className="form-control"
            value={bidAmount}
            onChange={(e) => setBidAmount(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={() => setShowBidModal(false)}>Cancel</Button> */}
          <Button variant="dark" onClick={handleBidSubmit}>Submit </Button>
        </Modal.Footer>
      </Modal>


            <ToastContainer />

        </>


    )
}

export default RecuiterModal


