import React, { useEffect, useRef, useState } from 'react'
 
import "./projectshowall.scss"
 
 
import { Helmet } from 'react-helmet'
 
import { Button } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ClearIcon from '@mui/icons-material/Clear';
import Header from '../../Layout/Header/Index';
import BreadCrump from '../../commonComponent/breadcrump/BreadCrump';
import LoadingPannel from '../../commonComponent/LoadingPannel/LoadingPannel';
import Footer from '../../Layout/Footer/Index';
import RecuiterModal from '../../components/FindRecuriter/RecuiterModal';
import { axiosApi } from '../../_helper/api_helper';

const ProjectShowaAll = () => {
   const navigate = useNavigate();
   const [searchParams, setSearchParams] = useSearchParams();
   const countryFlags = {
     India: "/flag/india.png",
     Australia: "/flag/australia.png",
   };
   const { category, subcategory, name } = useParams();
   const industryName = searchParams.get('industry')?.replace(/\+/g, ' ').replace(/&/g, ', ');
    
 
   const [modalShow, setModalShow] = React.useState(false);
   const [selectedRecruiterId, setSelectedRecruiterId] = useState(null);
   const [loading, setLoading] = useState(false)
   const [jobPostAll, setJobPostAll] = useState([])
   const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
   const [selectedSubCategoryIds, setSelectedSubCategoryIds] = useState([]);
   const [subCategory, setSubCategory] = useState([]);
   const [selectedLocations, setSelectedLocations] = useState([]);
   const [selectedSkills, setSelectedSkills] = useState([]);
   const [mutliJobId, setMutliJobId] = useState('')
   const [IndustriesData, setIndustriesData] = useState([]);
   const [cities, setCities] = useState([]);
 
   const [selectedSubCategory, setSelectedSubCategory] = useState(name ? [name] : []);
   const [selectedCategoryNames, setSelectedCategoryNames] = useState([]);
   const [selectedSubCategoryNames, setSelectedSubCategoryNames] = useState([]);
   const [selectedExperienceLevels, setSelectedExperienceLevels] = useState([]);
   const [minSalary, setMinSalary] = useState('');
   const [maxSalary, setMaxSalary] = useState('');
   const [minfixedSalary, setMinFixedSalary] = useState('');
   const [maxfixedSalary, setMaxFixedSalary] = useState('');
   // const [freelancerId, setFreelancerId] = useState('')
   const [error, setError] = useState(null);
   const [projectShow, setProjectShow] = useState(25);
   const [isFilterVisible, setFilterVisible] = useState(false);
   const [countries, setCountries] = useState([]);
   const filterCountry = countries.find((d) => d.country_name == "India")?.id;
     const [countryData, setCountryData] = useState([]);
   
   const handleLoadMore = () => {
     setProjectShow((prev) => prev + 25);
   };
 
 
 
   const handleClearAll = () => {
 
     setSelectedSkills([]);
     setSelectedExperienceLevels([]);
     setSelectedLocations([]);
     setMinSalary('');
     setMaxSalary('');
     setMinFixedSalary('');
     setMaxFixedSalary('');
     setSelectedCategoryIds([]);
     setSelectedCategoryNames([]);
     setSelectedSubCategoryIds([]);
     setSelectedSubCategoryNames([]);
     setSelectedSubCategory([])
   };
 
 
   useEffect(() => {
    countriesApi();
    
    if (filterCountry) {
        countriesGetById();
    }
}, [filterCountry]);
   useEffect(() => {
 
     // Fetch data
   }, [selectedSubCategory]);
 
  //  const effectRan = useRef(false);
 
  //  useEffect(() => {
  //   if (effectRan.current) return; // Prevent double execution
  
  //     effectRan.current = true;
  //    const params = {};
 
 
  //    if (selectedLocations.length > 0) params.city_new = selectedLocations.join('&');
  //    if (selectedSkills.length > 0) params.industry = selectedSkills.join('&');
  //    if (selectedSubCategory.length > 0) params.occupation_skill = selectedSubCategory.join('&');
  //    if (minSalary) params.min_fixedprice = minSalary;
  //    if (maxSalary) params.max_fixedprice = maxSalary;
  //    if (minfixedSalary) params.min_fixedprice = minfixedSalary;
  //    if (maxfixedSalary) params.max_fixedprice = maxfixedSalary;
 
  //    if (Object.keys(params).length > 0) {
  //      setSearchParams(params);  // Update URL with search params
  //    }
  //    const fetchFilteredData = async () => {
  //      try {
  //        const encodedLocations = selectedLocations.map(loc => encodeURIComponent(loc)).join(',');
  //        // const encodedSkills = selectedSkills.map(skill => encodeURIComponent(skill)).join(',');
  //        // const encodedoccupation_skill = selectedSubCategory.map(skill => encodeURIComponent(skill)).join(',');
  //        const encodedCategoryNames = selectedCategoryNames.map(name => encodeURIComponent(name)).join(',');  // Encode selected categories
  //        const encodedSubCategoryNames = selectedSubCategoryNames.map(name => encodeURIComponent(name)).join(',');
  //        const encodedExperienceLevels = selectedExperienceLevels.map(level => encodeURIComponent(level)).join(',');
  //        let projectPrice = '';
  //        let projectFixedPrice = '';
 
 
  //        if (minSalary) projectPrice = `min_perhour=${minSalary}`;
  //        if (maxSalary) projectPrice += projectPrice ? `&max_perhour=${maxSalary}` : `max_perhour=${maxSalary}`;
  //        if (minfixedSalary) projectFixedPrice = `min_fixedprice=${minfixedSalary}`;
  //        if (maxfixedSalary) projectFixedPrice += projectFixedPrice ? `&max_fixedprice=${maxfixedSalary}` : `max_fixedprice=${maxfixedSalary}`;
  //        const queryParams = [
  //          // encodedoccupation_skill && `Occupation_skills=${encodedoccupation_skill}`,
  //          encodedCategoryNames && `industry=${encodedCategoryNames}`,  // Add category to query params
  //          encodedSubCategoryNames && `occupation_skill=${selectedSubCategoryNames}`,
  //          // encodedSkills && `industry=${encodedSkills}`,
  //          // encodedoccupation_skill && `occupation_skill=${encodedoccupation_skill}`,
  //          encodedLocations && `city_new=${encodedLocations}`,
  //          encodedExperienceLevels && `experience_level=${encodedExperienceLevels}`,
  //          // projectPrice && `job_salary=${projectPrice}`
  //          projectPrice, projectFixedPrice
  //        ].filter(Boolean).join('&');
  //        const url = queryParams ? `/multijobs/all?${queryParams}` : '/multijobs/all';
 
  //        const response = await axiosApi.get(url);
 
  //        setJobPostAll(response?.data);
  //        setError(null);
  //      } catch (err) {
  //        if (err.response && err.response.data && err.response.data.error) {
  //          setError(err.response.data.error);
  //        } else {
  //          setError('An error occurred while fetching data.');
  //        }
  //        setJobPostAll([]);
  //      }
  //    };
 
  //    fetchFilteredData();
  //  }, [selectedLocations, subCategory, selectedSkills, selectedCategoryNames, selectedSubCategoryNames, selectedSubCategory, selectedExperienceLevels, minSalary, maxSalary, minfixedSalary, maxfixedSalary, setSearchParams]);
  const effectRan = useRef(false);

  useEffect(() => {
      // Prevent double API calls in strict mode, but allow re-runs when filters change
      if (effectRan.current === false) {
          effectRan.current = true; // Set flag to prevent initial double render in development
      } else {
          // Only re-run API call when filters change (e.g., selectedCategoryNames)
          if (!selectedCategoryNames.length) return;
      }
  
      const params = {};
      if (selectedLocations.length > 0) params.city_new = selectedLocations.join("&");
      if (selectedSkills.length > 0) params.industry = selectedSkills.join("&");
      if (selectedSubCategory.length > 0) params.occupation_skill = selectedSubCategory.join("&");
      if (minSalary) params.min_fixedprice = minSalary;
      if (maxSalary) params.max_fixedprice = maxSalary;
      if (minfixedSalary) params.min_fixedprice = minfixedSalary;
      if (maxfixedSalary) params.max_fixedprice = maxfixedSalary;
  
      if (Object.keys(params).length > 0) {
          setSearchParams(params);
      }
  
      const fetchFilteredData = async () => {
          try {
              const queryParams = [
                  selectedLocations.length > 0 ? `city_new=${selectedLocations.map(encodeURIComponent).join(",")}` : "",
                  selectedCategoryNames.length > 0 ? `industry=${selectedCategoryNames.map(encodeURIComponent).join(",")}` : "",
                  selectedSubCategoryNames.length > 0 ? `occupation_skill=${selectedSubCategoryNames.map(encodeURIComponent).join(",")}` : "",
                  selectedExperienceLevels.length > 0 ? `experience_level=${selectedExperienceLevels.map(encodeURIComponent).join(",")}` : "",
                  minSalary ? `min_perhour=${minSalary}` : "",
                  maxSalary ? `max_perhour=${maxSalary}` : "",
                  minfixedSalary ? `min_fixedprice=${minfixedSalary}` : "",
                  maxfixedSalary ? `max_fixedprice=${maxfixedSalary}` : "",
              ]
                  .filter(Boolean)
                  .join("&");
  
              const url = queryParams ? `/multijobs/all?${queryParams}` : "/multijobs/all";
              const response = await axiosApi.get(url);
  
              setJobPostAll(response?.data);
              setError(null);
          } catch (err) {
              setError(err.response?.data?.error || "An error occurred while fetching data.");
              setJobPostAll([]);
          }
      };
  
      fetchFilteredData();
  }, [
      selectedLocations,
      selectedSkills,
      selectedCategoryNames, // This makes API call re-run when industry filters change
      selectedSubCategoryNames,
      selectedSubCategory,
      selectedExperienceLevels,
      minSalary,
      maxSalary,
      minfixedSalary,
      maxfixedSalary,
      setSearchParams,
  ]);
 
 

  
  
 
   useEffect(() => {
     axiosApi.get("/industries")
       .then(response => {
         const data = response.data;
         setIndustriesData(data);
 
         // Find the ID for the 'Development' industry
         const selectedIndustry = data.find(industry => industry.name === category);
         if (selectedIndustry) {
           setSelectedCategoryIds([selectedIndustry.id]); // Set the ID in the state
           setSelectedCategoryNames([selectedIndustry.name]); // Optional: Set the name if needed
         }
       })
       .catch(error => {
         console.error("Error fetching industries", error);
       });
   }, [category]);
   useEffect(() => {
     // Fetch subcategories based on selected category IDs
     const fetchSubCategories = async () => {
       try {
         let allSubCategories = [];
         let matchedSubCategoryIds = [];
         for (const catId of selectedCategoryIds) {
           const response = await axiosApi.get(`/industries/${catId}`);
           const newSubCategories = response.data.occupation_skills.map(sub => ({
             ...sub,
             industry_id: catId
           }));
           allSubCategories = [...allSubCategories, ...newSubCategories];
 
           if (subcategory) {
             const filteredSub = newSubCategories.filter(sub => sub.name === subcategory);
             if (filteredSub.length > 0) {
               matchedSubCategoryIds = [...matchedSubCategoryIds, ...filteredSub.map(sub => sub.id)];
             }
           }
 
 
         }
         setSubCategory(allSubCategories);
 
         if (matchedSubCategoryIds.length > 0) {
           setSelectedSubCategoryIds(matchedSubCategoryIds);
         }
 
 
 
       } catch (error) {
         console.error("Error fetching subcategories", error);
       }
     };
 
     fetchSubCategories();
   }, [selectedCategoryIds, subcategory, navigate]);
   useEffect(() => {
 
     axiosApi.get("/industries").then((response) => {
       setIndustriesData(response.data);
     }).catch((error) => {
       console.error("Error fetching industries", error);
     });
   }, []);
   // const subCategoryData = async () => {
   //   const response = await axiosApi.get(`/occupation-skillss`);
   //   setSubCategory(response?.data);
   // }
   const citiesGet = async () => {
     const response = await axiosApi.get("/cities");
     setCities(response?.data)
   }
 
 
   const handleLocationChange = (event) => {
     const { name, checked } = event.target;
     setSelectedLocations(prev => checked ? [...prev, name] : prev.filter(loc => loc !== name));
   };
 
 
 
   const handleCategoryChange = async (e) => {
     const { id, checked, name } = e.target;  // Use the id as the key
 
     if (checked) {
       // Add the selected category by its ID
       setSelectedCategoryIds([...selectedCategoryIds, id]);
       setSelectedCategoryNames([...selectedCategoryNames, name]);
       setSelectedSkills([...selectedCategoryNames, name])
       // Fetch the subcategories for the selected category using the ID
       try {
         const response = await axiosApi.get(`/industries/${id}`);
         const newSubCategories = response.data.occupation_skills.map(sub => ({
           ...sub,
           industry_id: id  // Ensure each subcategory knows its parent industry ID
         }));
         setSubCategory((prevSubCategories) => [
           ...prevSubCategories,
           ...newSubCategories
         ]);
       } catch (error) {
         console.error("Error fetching subcategories", error);
       }
     } else {
       setSelectedCategoryIds(selectedCategoryIds.filter((categoryId) => categoryId !== id));
       setSelectedCategoryNames(selectedCategoryNames.filter((categoryName) => categoryName !== name));
       // Filter out the subcategories related to the unchecked category
       setSubCategory((prevSubCategories) =>
         prevSubCategories.filter((sub) => sub.industry_id !== id)
       );
 
     }
   };
   const handleSkillsChange = (e) => {
     const { id, checked, name } = e.target;  // Use id (subcategory ID) instead of name
 
     if (checked) {
       setSelectedSubCategoryIds([...selectedSubCategoryIds, id]);  // Add the ID to the selected array
       setSelectedSubCategoryNames([...selectedSubCategoryNames, name]);
     } else {
       setSelectedSubCategoryIds(selectedSubCategoryIds.filter((subId) => subId !== id));  // Remove ID if unchecked
       setSelectedSubCategoryNames(selectedSubCategoryNames.filter((subName) => subName !== name));
     }
   };
   const handleMinSalaryChange = (event) => {
     setMinSalary(event.target.value);
     if (event.target.value !== '') {
       setMinFixedSalary('');
       setMaxFixedSalary('');
     }
   };
 
   const handleMaxSalaryChange = (event) => {
     setMaxSalary(event.target.value);
     if (event.target.value !== '') {
       setMinFixedSalary('');
       setMaxFixedSalary('');
     }
   };
   const handleMinFixedSalaryChange = (event) => {
     setMinFixedSalary(event.target.value);
     if (event.target.value !== '') {
       setMinSalary('');
       setMaxSalary('');
     }
   };
   const handleMaxFixedSalaryChange = (event) => {
     setMaxFixedSalary(event.target.value);
     if (event.target.value !== '') {
       setMinSalary('');
       setMaxSalary('');
     }
   };
 
   const calculateTimeElapsed = (createdAt) => {
     const currentDate = new Date();
     const postedDate = new Date(createdAt);
     const timeDifference = currentDate - postedDate;
 
     const seconds = Math.floor(timeDifference / 1000);
     const minutes = Math.floor(seconds / 60);
     const hours = Math.floor(minutes / 60);
     const days = Math.floor(hours / 24);
     const weeks = Math.floor(days / 7);
     const months = Math.floor(days / 30);
     const years = Math.floor(days / 365);
 
     if (years > 0) {
       return `${years} ${years === 1 ? 'year' : 'years'} ago`;
     } else if (months > 0) {
       return `${months} ${months === 1 ? 'month' : 'months'} ago`;
     } else if (weeks > 0) {
       return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`;
     } else if (days > 0) {
       return `${days} ${days === 1 ? 'day' : 'days'} ago`;
     } else if (hours > 0) {
       return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
     } else if (minutes > 0) {
       return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
     } else {
       return `${seconds} ${seconds === 1 ? 'second' : 'seconds'} ago`;
     }
   };
 
 
 
 
   useEffect(() => {
     window.scroll({ top: 0, left: 0, behavior: "smooth" });
   }, [])
   const countriesApi = async () => {
     const response = await axiosApi.get("/countries");
     setCountries(response?.data)
   }
   const countriesGetById = async () => {
     try {
       const response = await axiosApi.get(`/countries/${filterCountry}/cities`);
       // const sortedData = response?.data?.sort((a, b) =>
       //     a.city_name.localeCompare(b.city_name)
       // );  
       const sortedData = response?.data?.sort((a, b) => {
         // Check if one of the items is "Others" and move it to the end
         if (a.city_name === "Others") return 1;
         if (b.city_name === "Others") return -1;
 
 
         return a.city_name.localeCompare(b.city_name);
       });
       setCountryData(sortedData);
     } catch (error) {
       console.error('Error fetching country data:', error);
     }
   };
   const toggleFilter = () => {
     setFilterVisible(!isFilterVisible);
   };
   const handleCloseFilter = () => {
     setFilterVisible(false);
   };
   return (
     <>
       <Helmet>
         <title>All Projects</title>
       </Helmet>
       <Header />
       <div className="bread-cumb-on">
         <BreadCrump title={"All Projects"} />
 
       </div>
       <div className="all-recuiter-filter-list">
         <div className="all-recuiter-filter-list-inner">
           <h5>All Projects</h5>
 
           {isFilterVisible ? (
             <span
               onClick={handleCloseFilter}
               style={{ cursor: 'pointer', marginLeft: 'auto', }}
             >
               <ClearIcon />
             </span>
           ) : <button onClick={toggleFilter}>
             <FilterListIcon />  Filters
           </button>}
         </div>
 
 
       </div>
       {loading && <LoadingPannel />}
       <div className='find-search-filter-container '>
         <div className="find-search-filter-container-inner">
           <div className={`find-search-filter-container-filter ${isFilterVisible ? 'show-filter' : ''}`}>
             <div
               onClick={handleCloseFilter}
               style={{ cursor: 'pointer', marginLeft: 'auto', }}
               className='close-filter-sidebar'
             >
               <ClearIcon />
             </div>
 
             <div className="applicant-filter-heading">
               <h6>Filters</h6>
 
               <h6 onClick={handleClearAll} style={{ cursor: 'pointer' }}>Clear All</h6>
 
             </div>
             <div className="applicant-filter-location">
               <h6>Budget</h6>
 
               <h6 className='mt-1 mt-md-3'>Hourly Projects</h6>
 
               <div className="price-filter-input">
                 <input type="number" placeholder='min-amt' value={minSalary}
                   onChange={handleMinSalaryChange} id="" /> <div>-</div>
                 <input type="number" placeholder='max-amt' value={maxSalary}
                   onChange={handleMaxSalaryChange} id="" />
 
 
               </div>
               
               <h6 className='mt-1 mt-md-3'>Fixed Price Projects</h6>
 
               <div className="price-filter-input">
                 <input type="number" placeholder='min-amt' value={minfixedSalary}
                   onChange={handleMinFixedSalaryChange} id="" /> <div>-</div>
                 <input type="number" placeholder='max-amt' value={maxfixedSalary}
                   onChange={handleMaxFixedSalaryChange} id="" />
               </div>
 
 
 
           
 
 
             </div>
             <div className="applicant-filter-location">
               <h6>Category</h6>
               {IndustriesData.map(industry => (
                 <div key={industry.id} className="city-checkbox">
                   <input
                     type="checkbox"
                     id={industry.id}
                     name={industry.name}
                     checked={selectedCategoryIds.includes(industry.id)}
                     onChange={handleCategoryChange}
                   />
                   <label htmlFor={industry.id}>{industry.name}</label>
                 </div>
               ))}
             </div>
             {subCategory.length > 0 && (
               <div className="applicant-filter-location">
                 <h6>Sub Category</h6>
 
                 {subCategory.map(sub => (
                   <div key={sub.id} className="city-checkbox">
                     <input
                       type="checkbox"
                       id={sub.id}
                       name={sub.name}
                       checked={selectedSubCategoryIds.includes(sub.id)}
                       onChange={handleSkillsChange}
                     />
                     <label htmlFor={sub.id}>{sub.name}</label>
                   </div>
                 ))}
               </div>
             )}
             <div className="applicant-filter-location">
               <h6>Location</h6>
               {countryData.map(city => (
                 <div key={city.id} className="city-checkbox">
                   <input
                     type="checkbox"
                     id={city.id}
                     name={city.city_name}
                     checked={selectedLocations.includes(city.city_name)}
                     onChange={handleLocationChange}
                   />
                   <label htmlFor={city.id}>{city.city_name}</label>
                 </div>
               ))}
             </div>
             {/* <div className="applicant-filter-location">
               <h6>Experience Level</h6>
               {experienceLevel.map(exp => (
                 <div key={exp.id} className="city-checkbox">
                   <input
                     type="checkbox"
                     id={exp.id}
                     name={exp.name}
                     checked={selectedExperienceLevels.includes(exp.name)}
                     onChange={handleExperienceChange}
                   />
                   <label htmlFor={exp.id}>{exp.name}</label>
                 </div>
               ))}
             </div> */}
           </div>
           <div className="all-recuiter-filter-container-card">
             {error ? (
               <div>{error}</div>
             ) : jobPostAll?.length > 0 ? (
               <>
                 {/* {industriesFilter.length > 0 &&
                   <div className="filter-industry">
                     Filter :  {industriesFilter.map((industry, index) => (
                       <span key={index} className="industry-item">
                         {industry}
                       </span>
                     ))}
                   </div>
                 } */}
 
                 {jobPostAll.slice(0, projectShow).map((item) => (
                   <div className="allrecuiter-content" key={item.project_id}>
                     <div className="flag-project-container">
                       {countryFlags[item?.country_name] ? (
                         <img src={countryFlags[item?.country_name]} alt="Country Flag" className="country-flag" />
                       ) : null}
 
                     </div>
                     <div className='job-title'>
                       <h5>{item?.project_title?.replace(/^"|"$/g, '')}</h5>
 
                     </div>
                     <h4 className='job-hostedby'>Hosted by {item?.recruiter_organisation_name || item?.recruiter_name}</h4>
                     {item?.project_city_new && (  <div className="applicant-location-name"><img src="/svg/location.svg" width={"20px"} height={'20px'} alt="" /> {item?.project_city_new}</div>)}
                   
                     <h4 className='job-category' style={{ color: "grey", fontSize: "14px", fontWeight: "600" }}>{item?.project_occupation_skill || item?.occupation_skill}</h4>
                     <div className='hiretalent-content-about'>{item?.project_description?.slice(0, 100)}...</div>
                     <div className="hiretalent-education">
                     
                       <div className="applicant-education-name">{item?.bid_count} Bid
                       </div>
                         <div className="applicant-education-name"> US$ {item?.min_perhour_usd && item?.max_perhour_usd
                           ? `${item.min_perhour_usd} - ${item.max_perhour_usd} Per/Hour`
                           : item?.min_fixedprice_usd && item?.max_fixedprice_usd
                             ? `${item.min_fixedprice_usd} - ${item.max_fixedprice_usd} Fix Price`
                             : 'N/A'}
                         </div>
                      
 
                     </div>
 
                    
                     <div className="project-border-line"></div>
                     <div className="apply-hiretalent">
                     
                      
                       <Link to={`/project-details/${item?.project_id}/${item?.recruiter_id}`}>
                       <Button  className='more-details' variant="outlined" >
                         View Details
                       </Button>
                       </Link>
                      
                       <h5>{calculateTimeElapsed(item.created_at)}</h5>
 
                     </div>
                   </div>
                 ))}
                 {projectShow < jobPostAll.length && (
                   <div className='show-more-button'><button className='  mt-2 ' onClick={handleLoadMore}>Show More</button></div>
 
                 )}
               </>
             ) : (
               <div>No result show</div>
             )}
           </div>
         </div>
       </div>
 
       <RecuiterModal
         show={modalShow}
         onHide={() => setModalShow(false)}
         filterData={selectedRecruiterId}
         mutliJobId={mutliJobId}
       // clientData={clientData}
       />
 
 
 
 
 
 
 
 
 
 
       <Footer />
     </>
   )
 } 
export default ProjectShowaAll
